import axios from 'axios';

export const useAxios = () => {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: config.public.baseURL,
    });

    const userToken = useCookie('token');
    const userGuestId = useCookie('guestId');
    const nuxtApp = useNuxtApp();

    const requestInterceptor = {
        onFulfilled: function (config) {
            if (nuxtApp.$i18n.locale._value) {
                config.headers['Accept-Language'] = nuxtApp.$i18n.locale._value;
            }
            if (userToken) {
                config.headers['Authorization'] = `Bearer ${userToken.value}`;
            }
            if (userGuestId) {
                config.headers['GuestId'] = userGuestId.value;
            }
            config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

            config.params = { ...config.params };

            return config;
        },
        onRejected: function (error) {
            return Promise.reject(error);
        },
    };

    const responseInterceptor = {
        onFulfilled: function (response) {
            return response;
        },
        onRejected: function (error) {
            console.log('Axios error', error);
            if (error.response && error.response.status === 401) {
                const userToken = useCookie('token');
                const userRefreshToken = useCookie('refreshToken');
                if (userRefreshToken.value) {
                    userToken.value = userRefreshToken.value;
                    userRefreshToken.value = null;
                } else {
                    userToken.value = null;
                }
                window.location.replace('/auth/login');
            }
            if (error.response && error.response.status === 403) {
                console.log(error.response.data.errors);
                return Promise.reject(error.response.data.errors);
            }
            return Promise.reject(error);
        },
    };

    api.interceptors.request.use(requestInterceptor.onFulfilled, requestInterceptor.onRejected);

    api.interceptors.response.use(responseInterceptor.onFulfilled, responseInterceptor.onRejected);
    return {
        api,
    };
};
