<template>
    <Head>
        <Title> {{ $t('Acceptix') }}</Title>
    </Head>
    <div @click="closeMenu">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup>
import { useAuthStore } from '../../libs/shared/store/auth.js';
import { useCountriesStore } from '../../libs/shared/store/countries.js';

const authStore = useAuthStore();
const { getProfile } = authStore;

const countriesStore = useCountriesStore();
const { getCountries, getDestinations } = countriesStore;

const token = useCookie('token');

onMounted(async () => {
    await nextTick();
    getCountries();
    getDestinations();
    if (token.value) {
        await getProfile();
    }
});
</script>

<script>
import { useFcm } from '@/composables/useFcm';

const { getFcm } = useFcm();
export default {
    async mounted() {
        this.$primevue.config.ripple = true;
        await getFcm();
    },
    methods: {
        closeMenu() {
            if (this.$store.state.functions.profileMenu) {
                this.$store.state.functions.profileMenu = false;
            }
            if (this.$store.state.functions.showNotification) {
                this.$store.state.functions.showNotification = false;
            }
            if (this.$store.state.functions.sort) {
                this.$store.state.functions.sort = false;
            }
        },
    },
};
</script>
