export const useAuthStore = defineStore('auth', () => {
    const { $api, $getApi } = useNuxtApp();

    // State
    const profile = ref(null);

    // Actions
    const login = async (email, password, remember = 0) => {
        const res = await $api.post(`auth/login`, { email, password, remember });
        if (res.isSuccess) {
            const resUser = res?.data?.data?.user;
            const resToken = res?.data?.data?.token;
            const resRefreshToken = res?.data?.data?.refresh_token;

            if (resUser) {
                profile.value = resUser;
                localStorage.setItem('userlogin', true);
                localStorage.setItem('email', resUser.email);
            }

            if (resToken) {
                const token = useCookie('token');
                token.value = resToken;
            }

            if (resRefreshToken && resRefreshToken !== resToken) {
                const refreshToken = useCookie('refreshToken');
                refreshToken.value = resRefreshToken;
            }
        }
        return res;
    };

    const getProfile = async () => {
        const res = await $getApi(`auth/profile`);
        profile.value = res.data?.data?.user;
    };

    const setProfile = data => {
        profile.value = data;
    };

    const getDocuments = async () => {
        const res = await $getApi(`auth/uploaded-documents`);
        return res.data?.data;
    };

    return {
        profile,
        login,
        setProfile,
        getProfile,
        getDocuments,
    };
});
