import { navigateTo, useCookie, useRuntimeConfig, useState, useAsyncData } from 'nuxt/app';
import { FetchOptions, FetchError, $fetch } from 'ofetch';

// Cache duration (session lifetime)
const CACHE_DURATION = 1000 * 60 * 60; // 1 hour
const NO_DATA_ERROR = 'No data received';

// Logger function
const logApiCall = (
    method: string,
    url: string,
    headers: Record<string, string>,
    response: unknown,
    error?: unknown
) => {
    try {
        const config = useRuntimeConfig();
        if (config.public.env === 'prod') return;
        console.group(`API ${method} Request to: ${url}`);
        console.log('Headers:', headers);

        if (error) {
            console.error('Error:', error);
        } else {
            console.log('Response:', response);
        }
    } catch (err) {
        console.error('Error in logging:', err);
    } finally {
        console.groupEnd();
    }
};

export type ApiError = {
    message: string;
    errors?: Record<string, string[]>;
};

export type ApiResponse<T> = {
    data?: T;
    isSuccess: boolean;
    statusCode: number;
    error?: ApiError;
};

export interface ApiOptions extends FetchOptions {
    withCache?: boolean;
}

interface CacheEntry {
    data: unknown;
    timestamp: number;
}

export const createApiService = () => {
    const config = useRuntimeConfig();
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = useCookie('i18n_redirected').value || 'en';
    const projectName = config.public.projectName;

    // Use Nuxt's useState for SSR-friendly caching
    const apiCache = useState<Map<string, CacheEntry>>('api-cache', () => new Map());

    // Check if component is mounted (client-side)
    const isMounted = () => {
        return process.client === true;
    };

    let baseURL;
    if (projectName === 'B2C') {
        baseURL = config.public['baseURL'] as string;
    } else {
        const userInfo = useCookie('subagent_userInfo');
        if (userInfo && userInfo.value) {
            const subdomain = JSON.parse(userInfo.value)?.subDomain;
            if (subdomain) {
                baseURL = `https://${subdomain}.${config.public['baseURL'] as string}`;
            }
        }
        baseURL = `https://${config.public['baseURL'] as string}`;
    }

    const getHeaders = () => {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain, */*',
            'X-Timezone': userTimezone,
            'Accept-Language': language,
        };
        const token = useCookie('token').value;
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const userGuestId = useCookie('guestId').value;
        if (userGuestId) {
            headers['GuestId'] = userGuestId;
        }
        return headers;
    };

    const handleError = (error: FetchError): ApiResponse<null> => {
        if (error.response?.status === 401) {
            navigateTo('/auth/login');
        }

        logApiCall('Error', baseURL + error.response?.url, getHeaders(), undefined, error.response);

        return {
            data: null,
            isSuccess: false,
            statusCode: error.response?.status || 500,
            error: {
                message: error.response?._data?.message || error.message || 'An error occurred',
                errors: error.response?._data?.errors,
            },
        };
    };

    const getCacheKey = (method: string, url: string, body?: unknown) => {
        return `${method}:${url}${body ? ':' + JSON.stringify(body) : ''}`;
    };

    const getFromCache = <T>(cacheKey: string): T | null => {
        const cached = apiCache.value.get(cacheKey);
        if (!cached) return null;

        const now = Date.now();
        if (now - cached.timestamp > CACHE_DURATION) {
            apiCache.value.delete(cacheKey);
            return null;
        }

        return cached.data as T;
    };

    const setToCache = <T>(cacheKey: string, data: T) => {
        const newCache = new Map(apiCache.value);
        newCache.set(cacheKey, {
            data,
            timestamp: Date.now(),
        });
        apiCache.value = newCache;
    };

    const clearCache = () => {
        apiCache.value = new Map();
    };

    const makeRequest = async <T, B extends Record<string, unknown> | undefined = undefined>(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        url: string,
        body?: B,
        options: ApiOptions = {}
    ): Promise<ApiResponse<T>> => {
        const { withCache = false, ...fetchOptions } = options;
        const headers = getHeaders();
        const cacheKey = getCacheKey(method, url, body);

        try {
            // Check cache for GET requests
            if (method === 'GET' && withCache) {
                const cachedData = getFromCache<T>(cacheKey);
                if (cachedData) {
                    logApiCall(`${method} Cache from Key: ${cacheKey}`, baseURL + url, headers, cachedData);
                    return {
                        data: cachedData,
                        isSuccess: true,
                        statusCode: 200, // Always 200 for cached data
                    };
                }
            }

            // Use different approach based on whether component is mounted
            if (!isMounted()) {
                // Use useAsyncData for SSR
                const { data, error } = await useAsyncData<T>(cacheKey, () =>
                    $fetch<T>(url, {
                        ...fetchOptions,
                        baseURL,
                        method,
                        ...(body && { body }),
                        headers: headers,
                        responseType: 'json',
                    })
                );

                if (error.value) {
                    throw error.value;
                }

                if (!data.value) {
                    throw new Error(NO_DATA_ERROR);
                }

                logApiCall(method, baseURL + url, headers, data.value);

                if (method === 'GET' && withCache) {
                    setToCache(cacheKey, data.value);
                }

                return {
                    data: data.value,
                    isSuccess: true,
                    statusCode: 200,
                };
            } else {
                // Direct fetch for client-side
                const response = await $fetch<T>(url, {
                    ...fetchOptions,
                    baseURL,
                    method,
                    ...(body && { body }),
                    headers: headers,
                    responseType: 'json',
                });

                logApiCall(method, baseURL + url, headers, response);

                if (method === 'GET' && withCache) {
                    setToCache(cacheKey, response);
                }

                return {
                    data: response,
                    isSuccess: true,
                    statusCode: 200,
                };
            }
        } catch (error) {
            return handleError(error as FetchError) as ApiResponse<T>;
        }
    };

    return {
        async get<T>(url: string, options: ApiOptions = {}) {
            return makeRequest<T>('GET', url, undefined, options);
        },

        async post<T, B extends Record<string, unknown>>(url: string, body: B, options: ApiOptions = {}) {
            return makeRequest<T, B>('POST', url, body, options);
        },

        async put<T, B extends Record<string, unknown>>(url: string, body: B, options: ApiOptions = {}) {
            return makeRequest<T, B>('PUT', url, body, options);
        },

        async delete<T>(url: string, options: ApiOptions = {}) {
            return makeRequest<T>('DELETE', url, undefined, options);
        },

        clearCache,
    };
};

// Composable to use the API service
export const useApi = () => {
    return createApiService();
};
