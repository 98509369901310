import flags from '../src/countries.json';
import { useCommonStore } from './search/common';

export const useCountriesStore = defineStore('countries', () => {
    const { $getApi } = useNuxtApp();
    const commonStore = useCommonStore();
    const { category } = storeToRefs(commonStore);

    // Destinations without logos
    const destinationsLoading = ref(true);
    const destinations = ref([]);
    const destinationsUniversity = ref([]);
    const destinationsEnglish = ref([]);
    // Destinations with logos
    const destinationsWithLogos = ref([]);
    const destinationsWithLogosUniversity = ref([]);
    const destinationsWithLogosEnglish = ref([]);

    // Countries
    const countries = ref([]);
    const loading = ref(true);

    // Get countries
    const getCountries = async () => {
        const res = await $getApi(`countries/registration`);
        countries.value = res.data?.data || [];
        countries.value = countries.value
            .filter(resItem => Boolean(resItem.iso_code))
            .map(resItem => {
                const country = flags?.data?.find(c => c.iso_code === resItem.iso_code);
                if (!country) return null;

                const show = country.flag || country.emoji || '';
                return {
                    id: resItem.id,
                    show,
                    code: resItem.code ?? '',
                    flag: country.flag ?? '',
                    name: resItem.name ?? '',
                    numberLength: resItem.number_length ?? 0,
                };
            })
            .filter(Boolean);
        loading.value = false;
    };

    // Get destinations
    const getDestinations = async (keyword = '') => {
        let query = `countries?is_map=1`;
        if (keyword) {
            query += `&filter[name]=${keyword}`;
        }

        try {
            destinationsLoading.value = true;
            const res = await $getApi(query);

            if (keyword) {
                let value;
                value = res.data.data;
                return category.value === 'university'
                    ? value?.filter(item => item.is_university)
                    : value?.filter(item => item.is_institute);
            } else {
                // Destinations without logos
                destinations.value = res.data?.data || [];
                destinationsUniversity.value = res.data?.data?.filter(item => item.is_university) || [];
                destinationsEnglish.value = res.data?.data?.filter(item => item.is_institute) || [];
                // Destinations with logos
                destinationsWithLogos.value = checkISOCode(destinations.value);
                destinationsWithLogosUniversity.value = checkISOCode(destinationsUniversity.value);
                destinationsWithLogosEnglish.value = checkISOCode(destinationsEnglish.value);
            }
        } catch (error) {
            console.error('Error fetching destinations:', error);
        } finally {
            destinationsLoading.value = false;
        }
    };

    const checkISOCode = list => {
        return list
            .filter(resItem => Boolean(resItem.iso_code))
            .map(resItem => {
                const country = flags?.data?.find(c => c.iso_code === resItem.iso_code);
                if (!country) return null;

                const show = country.flag || country.emoji || '';
                return {
                    id: resItem.id,
                    show,
                    code: resItem.code ?? '',
                    flag: country.flag ?? '',
                    name: resItem.name ?? '',
                    image: resItem.image,
                    numberLength: resItem.number_length ?? 0,
                    is_university: resItem.is_university,
                    is_institute: resItem.is_institute,
                };
            })
            .filter(Boolean);
    };

    return {
        countries,
        loading,
        destinations,
        destinationsUniversity,
        destinationsEnglish,
        destinationsWithLogos,
        destinationsWithLogosUniversity,
        destinationsWithLogosEnglish,
        destinationsLoading,
        getDestinations,
        getCountries,
    };
});
