export const useOnboardingStore = defineStore('onboarding', () => {
    const step = ref(1);
    const selectedPath = ref('');
    const loading = ref(false);
    const showRecommendationSection = ref(false);
    const onboardingData = ref({});
    const recommendationData = ref({});
    const recommendations = ref([]);
    const totalRecommendationsLength = ref(0);
    const recommendationsLastPage = ref(0);

    const getOnboardingData = async () => {
        const { api } = useAxios();
        try {
            loading.value = true;
            const response = await api.get('/onboarding');
            onboardingData.value = response.data?.data;
            onboardingData.value = handleResponse(response.data?.data);

            // Set guestId cookie
            const guestId = useCookie('guestId');
            guestId.value = response.data.guestId;
        } catch (error) {
            console.error(error);
        } finally {
            loading.value = false;
        }
    };

    const handleResponse = data => {
        return {
            ...data,
            meta: {
                ...data?.meta,
                englishTestsObject: {
                    haveYouTakeOptions: [
                        { id: 1, name: 'YesIHave' },
                        { id: 2, name: 'NoIHaveNot' },
                    ],
                    englishTestSelected:
                        data.selected.ielts_score || data.selected.toefl_score ? [{ id: 1, name: 'YesIHave' }] : [],
                    englishTests: [
                        { id: 1, name: 'IELTS' },
                        { id: 2, name: 'TOEFL' },
                    ],
                    selectedEnglishTest:
                        data.selected.ielts_score && data.selected.toefl_score
                            ? [
                                  { id: 1, name: 'IELTS' },
                                  { id: 2, name: 'TOEFL' },
                              ]
                            : data.selected.ielts_score
                            ? [{ id: 1, name: 'IELTS' }]
                            : data.selected.toefl_score
                            ? [{ id: 2, name: 'TOEFL' }]
                            : [],
                },
            },
        };
    };

    const sendOnboardingData = async () => {
        const { api } = useAxios();
        try {
            loading.value = true;
            const response = await api.put('/onboarding', onboardingData.value.selected);
            recommendationData.value = response.data?.recommendations;
        } catch (error) {
            console.error(error);
        } finally {
            loading.value = false;
            showRecommendationSection.value = true;
            step.value = 1;
        }
    };

    const getRecommendations = async page => {
        const { api } = useAxios();
        try {
            if ((totalRecommendationsLength.value == 0 || recommendationsLastPage.value >= page) && !loading.value) {
                loading.value = true;
                const response = await api.get(`/recommendations?page=${page}`);
                if (page == 1) {
                    recommendations.value = response.data.data;
                } else {
                    recommendations.value.push(...response.data.data);
                }
                totalRecommendationsLength.value = response.data.meta.total;
                recommendationsLastPage.value = response.data.meta.last_page;
            }
        } catch (error) {
            console.error(error);
        } finally {
            loading.value = false;
        }
    };

    return {
        step,
        loading,
        onboardingData,
        showRecommendationSection,
        selectedPath,
        recommendationData,
        recommendations,
        totalRecommendationsLength,
        getRecommendations,
        getOnboardingData,
        sendOnboardingData,
    };
});

